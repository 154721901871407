.wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(550px,1fr));
  column-gap: 25px;
  row-gap: 25px;
}
.container{
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  color: #444;
  /* padding: 20px 20px 20px 0; */
  padding: 20px;
  /* height: 200px; */
  min-height: fit-content;
  width: 100%;
  border-radius: 15px;
  box-shadow: 1px 2px 5px #00000034;
}
.container .image{
/* padding: 20px; */
height: 100%;
/* width: 100%; */
display: flex;
align-items: center;
}
.container .image img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.container .details{
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.container .details div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.container .details div .title{
  font-weight: 600;
  letter-spacing: 0.75px;
  margin: 0;
}
.container .details div .description{
  color:#94836f;
  margin: 10px 0;
}
.location{
  display: flex;
  align-items: center;
}