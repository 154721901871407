.Modal {
    z-index:200;
    position: fixed;
    left:40%;
    top:10%;
    /* left: calc(50% - 250px); */

    /* z-index: 500; */
    /* background-color: white; */
    /* width: 70%; */
    /* border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black; */
    /* padding: 16px; */
    /* left: 35%;
    top: 14%; */
    /* box-sizing: border-box; */
    transition: all 0.3s ease-out;
}

